import React from "react";
import { ChakraProvider, VStack } from "@chakra-ui/react";
import theme from "./theme";
import Header from "./Header";

// Import existing components
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import FeaturedLocations from "./FeaturedLocations";
import WorkWithUs from "./WorkWithUs";
import ExperienceOurApp from "./ExperienceOurApp"; // Add this import

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <VStack spacing={0} align="stretch">
        <Hero />
        <ExperienceOurApp />
        <HowItWorks />
      </VStack>
    </ChakraProvider>
  );
}

export default App;
