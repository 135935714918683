import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  Link,
  Image,
  useColorModeValue,
  VStack,
  HStack,
  Badge,
  useBreakpointValue,
  keyframes,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { FaApple } from "react-icons/fa";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionImage = motion(Image);

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 5px #ff00de, 0 0 10px #ff00de, 0 0 15px #ff00de, 0 0 20px #ff00de; }
  50% { text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff, 0 0 40px #00ffff; }
  100% { text-shadow: 0 0 5px #ff00de, 0 0 10px #ff00de, 0 0 15px #ff00de, 0 0 20px #ff00de; }
`;

const ExperienceOurApp = () => {
  const bgGradient = useColorModeValue(
    "linear(to-br, blue.50, purple.50, pink.50)",
    "linear(to-br, blue.900, purple.900, pink.800)"
  );
  const textColor = useColorModeValue("gray.800", "white");
  const accentColor = useColorModeValue("blue.500", "blue.300");

  const isMobile = useBreakpointValue({ base: true, md: false });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    {
      src: "https://ik.imagekit.io/mbhxghyf1m2/LoveLab%20ScreenShots/LoveLab1_VnKQzQmOx.png",
      title: "On Demand Dating Coach Messaging",
    },
    {
      src: "https://ik.imagekit.io/mbhxghyf1m2/LoveLab%20ScreenShots/LoveLab2_QtnJCfgKj.png",
      title: "Expert Profile Analysis",
    },
    {
      src: "https://ik.imagekit.io/mbhxghyf1m2/LoveLab%20ScreenShots/LoveLab3_iv29jtwXl.png",
      title: "Cutting Edge AI, Lightning Fast Insights",
    },
    {
      src: "https://ik.imagekit.io/mbhxghyf1m2/LoveLab%20ScreenShots/LoveLab4_g3bV2kFZt.png",
      title: "Simple To Use",
    },
    {
      src: "https://ik.imagekit.io/mbhxghyf1m2/LoveLab%20ScreenShots/LoveLab5_73CqX272b.png",
      title: "Adapted to your Dating Goals",
    },
    {
      src: "https://ik.imagekit.io/mbhxghyf1m2/LoveLab%20ScreenShots/LoveLab7_qotQzWGPto.png",
      title: "A Whole Lot of Fun",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      bgGradient={bgGradient}
      color={textColor}
      py={{ base: 10, md: 20 }}
      overflow="hidden"
      position="relative"
    >
      <Container maxW="container.xl">
        <VStack spacing={{ base: 12, md: 20 }}>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Heading as="h2" size={{ base: "3xl", md: "4xl" }} textAlign="center" fontWeight="extrabold">
              Experience <Text as="span" bgGradient="linear(to-r, blue.400, purple.500, pink.500)" bgClip="text">Zenith</Text>
            </Heading>
          </MotionBox>

          <MotionFlex
            direction={{ base: "column", lg: "row" }}
            align="center"
            justify="space-between"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Box flex={1} mr={{ base: 0, lg: 12 }} mb={{ base: 12, lg: 0 }}>
              <VStack align="start" spacing={{ base: 6, md: 10 }}>
                <Text fontSize={{ base: "xl", md: "2xl" }} lineHeight="tall" fontWeight="medium">
                  Optimize your profile, get more matches, and attract higher quality connections. Zenith's AI-powered tools expedite the dating process, helping you find meaningful relationships faster.
                </Text>
                <HStack spacing={4} flexWrap="wrap">
                  <Badge colorScheme="blue" fontSize={{ base: "md", md: "lg" }} px={4} py={2}>AI-Powered</Badge>
                  <Badge colorScheme="purple" fontSize={{ base: "md", md: "lg" }} px={4} py={2}>Personalized</Badge>
                  <Badge colorScheme="pink" fontSize={{ base: "md", md: "lg" }} px={4} py={2}>Secure</Badge>
                </HStack>
                <Link href="https://apps.apple.com/us/app/lovelab-ai/id6616619375" isExternal width="100%">
                  <Button
                    leftIcon={<FaApple />}
                    colorScheme="blue"
                    size="lg"
                    px={10}
                    py={7}
                    fontSize="xl"
                    fontWeight="bold"
                    borderRadius="full"
                    bgGradient="linear(to-r, blue.400, purple.500, pink.500)"
                    _hover={{
                      transform: "translateY(-5px) scale(1.05)",
                      boxShadow: "2xl",
                    }}
                    transition="all 0.3s"
                    width="100%"
                  >
                    Download on the App Store
                  </Button>
                </Link>
              </VStack>
            </Box>
            <Box flex={1} position="relative" width="100%" maxWidth="500px">
              <MotionBox
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                position="relative"
                width="100%"
              >
                <AnimatePresence mode="wait">
                  <MotionBox
                    key={currentImageIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    position="relative"
                    width="100%"
                    height="600px"
                  >
                    <MotionImage
                      src={images[currentImageIndex].src}
                      alt={images[currentImageIndex].title}
                      borderRadius="3xl"
                      width="100%"
                      height="100%"
                      objectFit="contain"
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -20, opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    />
                  </MotionBox>
                </AnimatePresence>
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  textAlign="center"
                  mt={4}
                  color={accentColor}
                  animation={`${glowAnimation} 3s infinite`}
                  textShadow="0 0 10px currentColor"
                >
                  {images[currentImageIndex].title}
                </Text>
              </MotionBox>
            </Box>
          </MotionFlex>
        </VStack>
      </Container>

      {!isMobile && (
        <>
          <MotionBox 
            position="absolute" top="5%" left="5%" width="50px" height="50px" borderRadius="full" 
            bgGradient="linear(to-r, blue.400, purple.500)"
            animate={{ y: [0, 30, 0], opacity: [1, 0.5, 1], scale: [1, 1.2, 1] }} 
            transition={{ repeat: Infinity, duration: 5 }} 
          />
          <MotionBox 
            position="absolute" top="80%" right="10%" width="40px" height="40px" borderRadius="full" 
            bgGradient="linear(to-r, purple.500, pink.500)"
            animate={{ y: [0, -20, 0], opacity: [1, 0.7, 1], scale: [1, 1.1, 1] }} 
            transition={{ repeat: Infinity, duration: 4, delay: 1 }} 
          />
          <MotionBox 
            position="absolute" top="40%" left="80%" width="60px" height="60px" borderRadius="full" 
            bgGradient="linear(to-r, pink.500, blue.400)"
            animate={{ y: [0, 40, 0], opacity: [1, 0.6, 1], scale: [1, 1.3, 1] }} 
            transition={{ repeat: Infinity, duration: 6, delay: 2 }} 
          />
        </>
      )}
    </Box>
  );
};

export default ExperienceOurApp;