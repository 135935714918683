import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  useColorModeValue,
  Icon,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaStar,
  FaAward,
  FaCrown,
  FaGem,
  FaCamera,
  FaTrophy,
} from "react-icons/fa";
import HubSpotForm2 from "./HubSpotForm2";

const MotionBox = motion(Box);

const ServiceTier = ({ title, description, features, icon, onClick }) => {
  const bgColor = useColorModeValue("red.50", "red.900");
  const borderColor = useColorModeValue("gold.200", "gold.600");

  return (
    <MotionBox
      whileHover={{ scale: 1.05, rotate: 1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
      bg={bgColor}
      borderWidth="2px"
      borderColor={borderColor}
      borderRadius="xl"
      p={8}
      height="100%"
      boxShadow="xl"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      cursor="pointer"
      onClick={onClick}
    >
      <VStack spacing={6} align="stretch">
        <Icon as={icon} boxSize={12} color="white" textShadow="2px 2px 4px rgba(0,0,0,0.5)" />
        <Heading as="h3" size="lg" color="white" textShadow="2px 2px 4px rgba(0,0,0,0.5)">
          {title}
        </Heading>
        <Text fontSize="md" color="white" textShadow="2px 2px 4px rgba(0,0,0,0.5)">{description}</Text>
        <VStack align="start" spacing={2}>
          {features.map((feature, index) => (
            <Flex key={index} align="center">
              <Icon as={FaGem} color="white" mr={2} textShadow="2px 2px 4px rgba(0,0,0,0.5)" />
              <Text color="white" textShadow="2px 2px 4px rgba(0,0,0,0.5)">{feature}</Text>
            </Flex>
          ))}
        </VStack>
      </VStack>
    </MotionBox>
  );
};

const FloatingIcon = ({ icon: IconComponent, top, left, delay }) => (
  <MotionBox
    position="absolute"
    animate={{
      y: [0, -20, 0],
      rotate: [0, 10, -10, 0],
    }}
    transition={{
      duration: 5,
      repeat: Infinity,
      repeatType: "reverse",
      delay,
    }}
    top={top}
    left={left}
  >
    <Icon as={IconComponent} boxSize={16} color="white" opacity={0.4} textShadow="2px 2px 4px rgba(0,0,0,0.5)" />
  </MotionBox>
);

const WorkWithUs = () => {
  const bgColor = useColorModeValue("red.100", "red.900");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState({});

  const handleTierClick = (title) => {
    setModalContent({ title });
    onOpen();
  };

  const serviceTiers = [
    {
      title: "Celebrity Brand Software",
      description: "Custom software to deliver exceptional value to your audience and own a digital asset with incredible returns.",
      features: [
        "Branded responsive design",
        "VIP content areas",
        "Exclusive fan engagement",
        "Social media integration",
      ],
      icon: FaStar,
    },
    {
      title: "Recurring Revenue Streams",
      description: "Amplify your digital presence with advanced monetization features.",
      features: [
        "Subscription-based content",
        "Virtual merchandise store",
        "Exclusive event ticketing",
        "Personalized fan experiences",
        "Automated marketing campaigns",
      ],
      icon: FaTrophy,
    },
    {
      title: "Premium Fan Experiences",
      description: "Cutting-edge applications for unparalleled fan engagement.",
      features: [
        "Interactive live streaming",
        "AI-powered fan interactions",
        "Virtual meet-and-greets",
        "Blockchain-based collectibles",
        "Global scalability",
        "24/7 VIP support",
      ],
      icon: FaCrown,
    },
  ];

  return (
    <Box bg={bgColor} minHeight="100vh" position="relative" overflow="hidden">
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        height="300px"
        bgGradient="linear(to-t, red.200, transparent)"
        opacity={0.3}
      />

      <FloatingIcon icon={FaCamera} top="25%" left="5%" delay={0} />
      <FloatingIcon icon={FaAward} top="40%" left="90%" delay={1} />
      <FloatingIcon icon={FaGem} top="70%" left="15%" delay={2} />

      <Container maxW="container.xl" pt={20} pb={20}>
        <VStack spacing={20}>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Heading
              as="h1"
              size="4xl"
              textAlign="center"
              color="white"
              letterSpacing="tight"
              mb={6}
              lineHeight="1.2"
              paddingBottom="0.2em"
              textShadow="0 0 10px rgba(255,255,255,0.3), 0 0 20px rgba(255,255,255,0.2), 0 0 30px rgba(255,255,255,0.1)"
              fontWeight="extrabold"
              position="relative"
              zIndex="1"
              _before={{
                content: '""',
                position: "absolute",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                zIndex: "-1",
              }}
            >
              Custom Software for Influencers            </Heading>
            <Text
              fontSize="2xl"
              textAlign="center"
              maxW="3xl"
              mx="auto"
              color="white"
              textShadow="0 0 5px rgba(255,255,255,0.2)"
              fontWeight="medium"
            >
              From exclusive fan experiences to full-scale digital empires, we offer solutions to amplify your celebrity brand at every level.
            </Text>
          </MotionBox>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} width="100%">
            <AnimatePresence>
              {serviceTiers.map((tier, index) => (
                <MotionBox
                  key={tier.title}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <ServiceTier
                    {...tier}
                    onClick={() => handleTierClick(tier.title)}
                  />
                </MotionBox>
              ))}
            </AnimatePresence>
          </SimpleGrid>
        </VStack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="red.50">
          <ModalHeader color="white" textShadow="2px 2px 4px rgba(0,0,0,0.5)">{modalContent.title}</ModalHeader>
          <ModalCloseButton color="white" textShadow="2px 2px 4px rgba(0,0,0,0.5)" />
          <ModalBody pb={6}>
            <HubSpotForm2 />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WorkWithUs;