import React from "react";
import {
  Box,
  Flex,
  useColorMode,
  IconButton,
  Container,
  Tooltip,
  Heading,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      as={motion.nav}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      bg={
        colorMode === "light" ? "rgba(66,153,225,0.8)" : "rgba(66,153,225,0.8)"
      }
      color="white"
      backdropFilter="blur(10px)"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={10}
    >
      <Container maxW="container.xl">
        <Flex py={4} alignItems="center" justifyContent="space-between">
          <Heading as="h1" size="lg">
            Zenith AI
          </Heading>
          <Flex alignItems="center">
            <Tooltip label="Toggle color mode">
              <IconButton
                icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                variant="ghost"
                aria-label="Toggle color mode"
                color="white"
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
