import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  VStack,
  Icon,
  useColorModeValue,
  Button,
  useBreakpointValue,
  Circle,
} from "@chakra-ui/react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import {
  FaCamera,
  FaChartLine,
  FaLightbulb,
  FaTrophy,
  FaRocket,
} from "react-icons/fa";

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const HowItWorks = () => {
  const bgGradient = useColorModeValue(
    "linear(to-tr, blue.50, purple.50, pink.50)",
    "linear(to-tr, blue.900, purple.900, pink.800)"
  );
  const cardBg = useColorModeValue(
    "rgba(255, 255, 255, 0.9)",
    "rgba(26, 32, 44, 0.9)"
  );
  const textColor = useColorModeValue("gray.800", "white");

  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 0.5], [0.9, 1]);
  const yOffset = useTransform(scrollYProgress, [0, 0.5], [100, 0]);
  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);

  const steps = [
    {
      icon: FaCamera,
      title: "Capture Profile",
      description:
        "Snap or upload your dating profile from any platform with ease.",
    },
    {
      icon: FaChartLine,
      title: "AI Analysis",
      description:
        "Our cutting-edge AI examines every aspect of your profile for improvement.",
    },
    {
      icon: FaLightbulb,
      title: "Smart Insights",
      description:
        "Receive tailored, easy-to-implement suggestions to boost your profile's appeal.",
    },
    {
      icon: FaTrophy,
      title: "Track Success",
      description:
        "Watch your match rate and profile score skyrocket as you implement changes!",
    },
  ];

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box bgGradient={bgGradient} py={20} overflow="hidden" position="relative">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Heading
              as="h2"
              size="3xl"
              textAlign="center"
              color={textColor}
              fontWeight="extrabold"
            >
              How It{" "}
              <Text
                as="span"
                bgGradient="linear(to-r, blue.400, purple.500, pink.500)"
                bgClip="text"
              >
                Works
              </Text>
            </Heading>
          </MotionBox>

          <MotionFlex
            direction={isSmallScreen ? "column" : "row"}
            justify="space-between"
            align="stretch"
            w="100%"
            style={{ scale, y: yOffset, opacity }}
          >
            {steps.map((step, index) => (
              <MotionBox
                key={index}
                bg={cardBg}
                p={6}
                borderRadius="2xl"
                boxShadow="xl"
                backdropFilter="blur(10px)"
                flex={1}
                m={2}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ y: -10, boxShadow: "2xl" }}
              >
                <VStack
                  spacing={4}
                  align="center"
                  textAlign="center"
                  height="100%"
                  justify="space-between"
                >
                  <Circle
                    size="80px"
                    bgGradient="linear(to-r, blue.400, purple.500, pink.500)"
                    color="white"
                  >
                    <Icon as={step.icon} w={10} h={10} />
                  </Circle>
                  <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                    {step.title}
                  </Text>
                  <Text color={textColor} fontSize="md">
                    {step.description}
                  </Text>
                </VStack>
              </MotionBox>
            ))}
          </MotionFlex>

          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            textAlign="center"
          >
            <Text
              fontSize="2xl"
              color={textColor}
              maxW="900px"
              mb={10}
              fontWeight="medium"
            >
              Experience the future of online dating optimization with Zenith.
              Elevate your profile, increase your matches, and find meaningful
              connections!
            </Text>
            <Button
              size="lg"
              colorScheme="blue"
              rightIcon={<Icon as={FaRocket} />}
              _hover={{
                transform: "translateY(-5px) scale(1.05)",
                boxShadow: "xl",
              }}
              transition="all 0.3s"
              px={10}
              py={6}
              fontSize="2xl"
              fontWeight="bold"
              borderRadius="full"
              bgGradient="linear(to-r, blue.400, purple.500, pink.500)"
            >
              Launch Your Journey
            </Button>
          </MotionBox>
        </VStack>
      </Container>

      {/* Animated background elements */}
      <MotionBox
        position="absolute"
        top="5%"
        left="5%"
        width="20px"
        height="20px"
        borderRadius="full"
        bgGradient="linear(to-r, blue.400, purple.500)"
        animate={{ y: [0, 30, 0], opacity: [1, 0.5, 1], scale: [1, 1.2, 1] }}
        transition={{ repeat: Infinity, duration: 5 }}
      />
      <MotionBox
        position="absolute"
        top="80%"
        right="10%"
        width="15px"
        height="15px"
        borderRadius="full"
        bgGradient="linear(to-r, purple.500, pink.500)"
        animate={{ y: [0, -20, 0], opacity: [1, 0.7, 1], scale: [1, 1.1, 1] }}
        transition={{ repeat: Infinity, duration: 4, delay: 1 }}
      />
      <MotionBox
        position="absolute"
        top="40%"
        left="80%"
        width="25px"
        height="25px"
        borderRadius="full"
        bgGradient="linear(to-r, pink.500, blue.400)"
        animate={{ y: [0, 40, 0], opacity: [1, 0.6, 1], scale: [1, 1.3, 1] }}
        transition={{ repeat: Infinity, duration: 6, delay: 2 }}
      />
    </Box>
  );
};

export default HowItWorks;
