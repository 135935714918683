import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  useColorModeValue,
  VStack,
  Image,
  Container,
  useBreakpointValue,
  useToast,
  UnorderedList,
  ListItem,
  keyframes,
  Icon,
  Heading,
  Divider,
  Stack,
  Link,
  IconButton,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  FaStar,
  FaAward,
  FaGem,
  FaApple,
  FaComments,
  FaRocket,
  FaHeart,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";

// Subtle floating animation
const floatingAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
  100% { transform: translateY(0); }
`;

const FloatingElement = ({ children }) => (
  <Box
    animation={`${floatingAnimation} 2s ease-in-out infinite`}
    display="inline-block"
  >
    {children}
  </Box>
);

const Hero = ({ handleStartJourney, buttonSize }) => {
  const overlayBg = useColorModeValue("rgba(0,0,0,0.4)", "rgba(0,0,0,0.6)");
  const logoSize = useBreakpointValue({ base: "60%", sm: "50%", md: "40%" });
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      rotate: -10,
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
        duration: 0.8,
      },
    },
  };

  const handleDownload = () => {
    toast({
      title: "Downloading App",
      description: "Thank you for downloading LoveLab!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const mobileCardVariants = {
    hidden: { opacity: 0, x: "-100%" },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <Box
      as="section"
      position="relative"
      minHeight="100vh"
      width="100%"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* Video Background */}
      <Box
        as="video"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        src="https://sybassets.s3.us-west-1.amazonaws.com/couple.mp4"
        autoPlay
        loop
        muted
        playsInline
      />

      {/* Overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg={overlayBg}
      />

      {/* Content */}
      <Container
        maxW="container.xl"
        position="relative"
        zIndex="1"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        py={{ base: 16, md: 24 }} // Increased top padding for mobile
      >
        <VStack
          spacing={{ base: 4, md: 8 }}
          align="center"
          justify="center"
          color="white"
        >
          <motion.div
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          ></motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <Box
              bg="rgba(0, 0, 0, 0.7)"
              borderRadius="2xl"
              p={{ base: 4, sm: 6, md: 8 }}
              boxShadow="0 8px 32px rgba(31, 38, 135, 0.37)"
              backdropFilter="blur(4px)"
              border="1px solid rgba(255, 255, 255, 0.18)"
              width="100%"
              maxWidth="800px"
              mx="auto"
            >
              <VStack spacing={{ base: 3, md: 6 }} align="stretch">
                <Heading
                  as="h1"
                  fontSize={{ base: "xl", sm: "2xl", md: "4xl", lg: "5xl" }}
                  fontWeight="extrabold"
                  textAlign="center"
                  bgGradient="linear(to-r, blue.400, purple.500, pink.500)"
                  bgClip="text"
                >
                  Zenith AI
                </Heading>
                <Text
                  fontSize={{ base: "sm", sm: "md", md: "xl" }}
                  textAlign="center"
                  color="gray.300"
                >
                  Tired of swiping with no success?
                  <br />
                  Start attracting the matches you deserve with LoveLab!
                </Text>
                <Divider my={{ base: 2, md: 4 }} borderColor="gray.600" />
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  justify="center"
                  wrap="wrap"
                  spacing={{ base: 2, md: 4 }}
                >
                  {[
                    { icon: FaStar, text: "AI-Powered Precision" },
                    { icon: FaAward, text: "Perfect Your Photos" },
                    { icon: FaGem, text: "Boost Your Bio" },
                    { icon: FaComments, text: "Nail Your Prompts" },
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      variants={mobileCardVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 0.5, delay: index * 0.2 }}
                      style={{ width: "100%" }}
                    >
                      <Box
                        bg="rgba(255, 255, 255, 0.1)"
                        borderRadius="lg"
                        p={3}
                        textAlign="center"
                        flex="1"
                        minW={{ base: "100%", sm: "150px" }}
                      >
                        <Icon
                          as={item.icon}
                          color="blue.300"
                          w={5}
                          h={5}
                          mb={2}
                        />
                        <Text
                          fontSize={{ base: "xs", sm: "sm", md: "md" }}
                          fontWeight="medium"
                        >
                          {item.text}
                        </Text>
                      </Box>
                    </motion.div>
                  ))}
                </Stack>

                <Flex justifyContent="center" mb={6} mt={4} width="100%">
                  <Flex
                    direction={{ base: "column", sm: "row" }}
                    width="100%"
                    maxWidth="400px"
                    justifyContent="space-between"
                  >
                    <Link
                      href="https://www.instagram.com/lovelabai/"
                      isExternal
                      width={{ base: "100%", sm: "48%" }}
                      mb={{ base: 4, sm: 0 }}
                    >
                      <Button
                        leftIcon={<Icon as={FaInstagram} boxSize={6} />}
                        size="lg"
                        colorScheme="pink"
                        variant="solid"
                        _hover={{
                          transform: "translateY(-2px)",
                          boxShadow: "xl",
                          bg: "pink.500",
                        }}
                        transition="all 0.3s"
                        borderRadius="full"
                        width="100%"
                      >
                        Instagram
                      </Button>
                    </Link>
                    <Link
                      href="https://www.tiktok.com/@lovelabapp?lang=en"
                      isExternal
                      width={{ base: "100%", sm: "48%" }}
                    >
                      <Button
                        leftIcon={<Icon as={FaTiktok} boxSize={6} />}
                        size="lg"
                        colorScheme="gray"
                        variant="solid"
                        _hover={{
                          transform: "translateY(-2px)",
                          boxShadow: "xl",
                          bg: "gray.700",
                        }}
                        transition="all 0.3s"
                        borderRadius="full"
                        width="100%"
                      >
                        TikTok
                      </Button>
                    </Link>
                  </Flex>
                </Flex>

                {/* Download App Button */}
                <Link
                  href="https://apps.apple.com/us/app/lovelab-ai/id6616619375"
                  isExternal
                  width="100%"
                >
                  <Button
                    leftIcon={<FaApple />}
                    rightIcon={<Icon as={FaHeart} />}
                    colorScheme="pink"
                    size={{ base: "md", md: "lg" }}
                    px={{ base: 6, md: 10 }}
                    py={{ base: 5, md: 7 }}
                    fontSize={{ base: "md", md: "xl" }}
                    fontWeight="bold"
                    borderRadius="full"
                    bgGradient="linear(to-r, blue.400, purple.500, pink.500)"
                    _hover={{
                      transform: "translateY(-5px)",
                      boxShadow: "0 10px 20px rgba(255, 105, 180, 0.4)",
                    }}
                    transition="all 0.3s"
                    onClick={handleDownload}
                    width="100%"
                    mt={{ base: 4, md: 6 }}
                  >
                    Download on the App Store
                  </Button>
                </Link>
              </VStack>
            </Box>
          </motion.div>
        </VStack>
      </Container>
    </Box>
  );
};

export default Hero;
